(function () {
    function r(e, n, t) {
        function o(i, f) {
            if (!n[i]) {
                if (!e[i]) {
                    var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
                }var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
                    var n = e[i][1][r];return o(n || r);
                }, p, p.exports, r, e, n, t);
            }return n[i].exports;
        }for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
            o(t[i]);
        }return o;
    }return r;
})()({ 1: [function (require, module, exports) {
        $(document).ready(function () {
            $("body").on('click', '.app-download__form-submit', function () {
                var $form = $('.app-download__form');
                var params = $form.serialize();
                $.ajax('https://ht.kz/api1/publicSms/mobileApp', {
                    method: 'POST',
                    data: params,
                    dataType: 'json'
                }).done(function (data) {
                    var cssClass = data.success ? 'form-result--success' : 'form-result--error';
                    $form.addClass('form-result ' + cssClass);
                    $form.before().append('<p class="app-download__form-result">' + data.info + '</p>');
                });
            });
        });

        var onDOMContentLoaded = function onDOMContentLoaded() {
            var lazyClass = 'animation';

            var lazySelectors = [].slice.call(document.querySelectorAll('.section-feature'));
            if ('IntersectionObserver' in window && lazySelectors.length) {
                var lazySelectorsObserverCallback = function lazySelectorsObserverCallback(entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            entry.target.classList.add(lazyClass);
                            observer.unobserve(entry.target);
                        }
                    });
                };
                var lazySelectorsObserver = new IntersectionObserver(lazySelectorsObserverCallback, {
                    threshold: 0.3
                });
                var registerObserverEntries = function registerObserverEntries() {
                    lazySelectors.forEach(function (lazySelector) {
                        lazySelectorsObserver.observe(lazySelector);
                    });
                };
                if (window.requestIdleCallback) {
                    window.requestIdleCallback(registerObserverEntries);
                } else {
                    registerObserverEntries();
                }
            }

            document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
        };
        document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
    }, {}] }, {}, [1]);